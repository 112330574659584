import * as Redux from '@reduxjs/toolkit';
import * as ShellSlice from './shell/shell.slice';
import * as HomeSlice from './home/home.slice';
import * as VerifySlice from './verify/verify.slice';
import * as OrganizationsSlice from './user-account-management/organizations/organizations.slice';
import * as AddOrganizationSlice from './user-account-management/organizations/add/add-organization.slice';
import * as OrganizationUsersSlice from './user-account-management/organizations/users/organization-users.slice';
import * as AddOrganizationInvitationsSlice from './user-account-management/organizations/invitations/add/add-organization-invitation.slice';
import * as OrganizationInvitationsSlice from './user-account-management/organizations/invitations/organization-invitations.slice';
import * as UpdateOrganizationMemberSlice from './user-account-management/organizations/users/edit/edit-organization-user.slice';

export const slices = {
	[ShellSlice.slice.name]: ShellSlice,
	[HomeSlice.slice.name]: HomeSlice,
	[VerifySlice.slice.name]: VerifySlice,
	[OrganizationsSlice.slice.name]: OrganizationsSlice,
	[AddOrganizationSlice.slice.name]: AddOrganizationSlice,
	[OrganizationUsersSlice.slice.name]: OrganizationUsersSlice,
	[AddOrganizationInvitationsSlice.slice.name]: AddOrganizationInvitationsSlice,
	[OrganizationInvitationsSlice.slice.name]: OrganizationInvitationsSlice,
	[UpdateOrganizationMemberSlice.slice.name]: UpdateOrganizationMemberSlice	
};

export function createRootReducer() {
	return Redux.combineReducers({
		[ShellSlice.slice.name]: ShellSlice.slice.reducer,
		[HomeSlice.slice.name]: HomeSlice.slice.reducer,
		[VerifySlice.slice.name]: VerifySlice.slice.reducer,
		[OrganizationsSlice.slice.name]: OrganizationsSlice.slice.reducer,
		[AddOrganizationSlice.slice.name]: AddOrganizationSlice.slice.reducer,
		[OrganizationUsersSlice.slice.name]: OrganizationUsersSlice.slice.reducer,
		[AddOrganizationInvitationsSlice.slice.name]: AddOrganizationInvitationsSlice.slice.reducer,
		[OrganizationInvitationsSlice.slice.name]: OrganizationInvitationsSlice.slice.reducer,
		[UpdateOrganizationMemberSlice.slice.name]: UpdateOrganizationMemberSlice.slice.reducer
	});
}
