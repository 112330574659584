import * as Redux from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteOrganizationInvitation, getOrganizationInvitations } from "../../../hubs/UserAccountManagementController.hub";
import { OrganizationInvitation } from "../models";


export interface SliceProps {
    status: "initial" | "loading" | "loaded" | "error",
    organizationInvitations: OrganizationInvitation[],
    deletions: { [invitationId: string]: "pending" | "success" | "error" },
}

export function makeInitialState(): SliceProps {
    return {
        status: "initial",
        organizationInvitations: [],
        deletions: {},
    };
}

export const thunks = {
    getOrganizationInvitations: createAsyncThunk(
        "organizationInvitations/getOrganizationInvitations",
        async (organizationId:string) => getOrganizationInvitations(organizationId)
    ),
    deleteOrganizationInvitation: createAsyncThunk(
        "organizationInvitations/deleteOrganizationInvitation",
        async ({ organizationId, invitationId }: { organizationId: string, invitationId: string }) => {
            const response = await deleteOrganizationInvitation(organizationId, invitationId);
            return { 
                status: response.status as "2xx" | "5xx" | "network-error",
                organizationId, 
                invitationId 
            };
        }
    )
}

export const slice = Redux.createSlice({
    name: "organizationInvitations",
    initialState: makeInitialState(),
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(thunks.getOrganizationInvitations.pending, (state) => {
            state.status = "loading";
        });

        builder.addCase(thunks.getOrganizationInvitations.fulfilled, (state, action) => {
            if (action.payload.status === "2xx") {
                state.organizationInvitations = action.payload.organizationInvitations;
                state.status = "loaded";
            } else {
                state.status = "error";
            }
        });

        builder.addCase(thunks.getOrganizationInvitations.rejected, (state) => {
            state.status = "error";
        });

        builder.addCase(thunks.deleteOrganizationInvitation.pending, (state, action) => {
            state.deletions[action.meta.arg.invitationId] = "pending";
        });

        builder.addCase(thunks.deleteOrganizationInvitation.fulfilled, (state, action) => {
            if (action.payload.status === "2xx") {
                state.organizationInvitations = state.organizationInvitations.filter(
                    invitation => invitation.id !== action.payload.invitationId
                );

                state.deletions[action.payload.invitationId] = "success";
            } else {
                state.deletions[action.payload.invitationId] = "error";
            }
        });

        builder.addCase(thunks.deleteOrganizationInvitation.rejected, (state, action) => {
            state.deletions[action.meta.arg.invitationId] = "error";
        });
    }
});

