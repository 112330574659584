import { Organization, OrganizationInvitation, OrganizationInvitationTransient, OrganizationMember, OrganizationTransient, UpdateOrganizationMemberRequest } from "../user-account-management/organizations/models";
import { http } from "./hub-utils";

export async function getOrganizations() {
	const response = await http.get<Organization[]>("/api/user-account-management/organizations");

	return response.status === "2xx" ? {
		organizations: response.body,
		status: response.status
	} : {
		status: response.status
	};
}

export async function addOrganization(organization: OrganizationTransient) {
	const response = await http.post<Organization>("/api/user-account-management/organizations", organization);

	return response.status === "2xx" ? {
		organization: response.body,
		status: response.status
	} : {
		status: response.status
	};
}

export async function getOrganizationMembers(organizationId: string) {
	const response = await http.get<OrganizationMember[]>(`/api/user-account-management/organizations/${organizationId}/members`);

	return response.status === "2xx" ? {
		organizationMembers: response.body,
		status: response.status
	} : {
		status: response.status
	};
}

export async function getOrganizationMember(organizationId: string, userId: string) {
	const response = await http.get<OrganizationMember>(`/api/user-account-management/organizations/${organizationId}/members/${userId}`);

	return response.status === "2xx" ? {
		organizationMember: response.body,
		status: response.status
	} : {
		status: response.status
	};
}

export async function updateOrganizationMember(organizationId: string, userId: string, request: UpdateOrganizationMemberRequest) {
	const response = await http.put<string>(`/api/user-account-management/organizations/${organizationId}/members/${userId}`, request);

	return {
		status: response.status 
	};
}

export async function addOrganizationInvitation(organizationId: string, invitation: OrganizationInvitationTransient) {
	const response = await http.post<OrganizationInvitation>(`/api/user-account-management/organizations/${organizationId}/invitations`, invitation);

	return response.status === "2xx" ? {
		invitations: response.body,
		status: response.status
	} : {
		status: response.status
	};
}

export async function getOrganizationInvitations(organizationId: string) {
	const response = await http.get<OrganizationInvitation[]>(`/api/user-account-management/organizations/${organizationId}/invitations`);

	return response.status === "2xx" ? {
		organizationInvitations: response.body,
		status: response.status
	} : {
		status: response.status
	};
}

export async function deleteOrganizationInvitation(organizationId: string, invitationId: string) {
	const response = await http.del(`/api/user-account-management/organizations/${organizationId}/invitations/${invitationId}`);

	return {
		status: response.status
	};
}

export async function getRoles() {
	const response = await http.get<string[]>("/api/account/roles");

	return response.status === "2xx" ? {
		roles: response.body,
		status: response.status
	} : {
		status: response.status
	};
}