import {
    Button,
    Card,
    DsxLoadingSpinner,
    MdClose,
    MdEmail,
    MdError,
    Modal,
    SearchBar,
    StandaloneIcon,
    Table,
    Text,
    useToast,
} from "@dsx/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PageProps, useSlice } from "../../../util/page";
import siteStyles from "../../site.module.css";
import { OrganizationInvitation } from "../models";
import pageStyles from "./organization-invitations.module.css";
import { thunks } from "./organization-invitations.slice";
import { getStore } from "../../../store";

export function OrganizationInvitations(props: PageProps<"organizationInvitations">) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { organizationId } = useParams<{ organizationId: string }>();
    const popToast = useToast();

    const [searchText, setSearchText] = React.useState("");
    const [searchScope, setSearchScope] = React.useState("");
    const [selectedInvitation, setSelectedInvitation] = React.useState<OrganizationInvitation | null>(null);

    useEffect(() => {
        if (organizationId) {
            dispatch(thunks.getOrganizationInvitations(organizationId));
        }
    }, [dispatch, organizationId]);

    const isPendingDeletion = (invitationId: string): boolean => props.deletions[invitationId] && props.deletions[invitationId] === "pending";

    const deleteOrganizationInvitation = async (invitation: OrganizationInvitation) => {
        if (!organizationId) return;

        try {
            await dispatch(thunks.deleteOrganizationInvitation({ organizationId, invitationId: invitation.id }));

            const deletionStatus = getStore().getState().organizationInvitations.deletions[invitation.id];

            if (deletionStatus === "success") {
                popToast(
                    <span>
                        Invitation for <b>{invitation?.inviteeEmail}</b> deleted.
                    </span>, {
                    title: "Invitation Deleted",
                    variant: "success",
                    position: "top-center",
                    autoClose: 5000,
                }
                );

                return;
            }
        } catch (error) {
            console.log(error);
        }

        popToast(
            <span>
                Failed to delete invitation for <b>{invitation?.inviteeEmail}</b>. Please try again.
            </span>, {
            title: "Error",
            variant: "danger",
            position: "top-center",
            autoClose: false,
        }
        );
    }

    const filteredInvitations = searchText.length > 0
        ? props.organizationInvitations.filter((invitation) => {
            if (searchScope === "inviteeEmail") {
                return invitation.inviteeEmail.toLowerCase().includes(searchText.toLowerCase());
            } else if (searchScope === "inviterName") {
                return invitation.inviterName.toLowerCase().includes(searchText.toLowerCase());
            } else if (searchScope === "expDate") {
                return new Date(invitation.expiresAt).toLocaleDateString().includes(searchText);
            } else {
                return invitation.inviteeEmail.toLowerCase().includes(searchText.toLowerCase()) ||
                    invitation.inviterName.toLowerCase().includes(searchText.toLowerCase()) ||
                    new Date(invitation.expiresAt).toLocaleDateString().includes(searchText);
            }
        })
        : props.organizationInvitations;

    return (
        <div className={siteStyles["block-container"]}>
            <Card>
                <div className={pageStyles["header-container"]}>
                    <div>
                        <Text variant="headline-1">View Organization Invitations</Text>
                        <br></br>
                        <Text>
                            gravida neque convallis a cras semper auctor neque vitae tempus quam
                            pellentesque nec nam aliquam sem et tortor consequat id porta nibh
                            venenatis cras sed felis eget velit aliquet sagittis id consectetur purus
                            ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt
                            vitae semper quis lectus nulla at volutpat
                        </Text>
                    </div>
                    <div className={siteStyles["button-container"]}>
                        <Button
                            variant="ui-control"
                            onClick={() => navigate(`/user-account-management/organizations/${organizationId}/users`)}
                        >
                            Manage Users
                        </Button>
                        <Button
                            variant="primary"
                            icon={MdEmail}
                            onClick={() => navigate(`/user-account-management/organizations/${organizationId}/invitations/add`)}
                        >
                            Invite User
                        </Button>
                    </div>
                </div>
            </Card>
            {(props.status === "loading") && (
                <div className={siteStyles["loading-container"]}>
                    <StandaloneIcon
                        icon={DsxLoadingSpinner}
                        title={"Loading invitations..."}
                        size="size-1100"
                    />
                </div>
            )}
            {props.status === "error" && (
                <div className={siteStyles["icon-block-container"]}>
                    <div className={siteStyles["centered-container"]}>
                        <StandaloneIcon
                            icon={MdError}
                            title="Error"
                            size="size-1100"
                        />
                    </div>
                    <div className={siteStyles["centered-container"]}>
                        <Text variant="body">An error occurred. Please try again.</Text>
                    </div>
                </div>
            )}
            {(props.status === "loaded") && (
                <div>
                    <div>
                        <SearchBar
                            search={searchText}
                            onScopeChange={setSearchScope}
                            onSearchChange={setSearchText}
                            onSearch={setSearchText}
                            placeholder="Search Invitations"
                            scopeOptions={[
                                { label: "All", value: "" },
                                { label: "Email", value: "inviteeEmail" },
                                { label: "Inviter", value: "inviterName" },
                                { label: "Expiration Date", value: "expDate" },
                            ]}
                        />
                    </div>
                    <div style={{ width: "100%", marginTop: "10px"}}>
                        <Table
                            columns={[
                                { key: "inviteeEmail", heading: "Email" },
                                { key: "inviterName", heading: "Inviter" },
                                { key: "expiresAt", heading: "Expiration Date" },
                                { key: "actions", heading: "Actions" },
                            ]}
                            rows={filteredInvitations.map((invitation) => ({
                                invitationId: invitation.id,
                                inviteeEmail: invitation.inviteeEmail,
                                inviterName: invitation.inviterName,
                                expiresAt: new Date(invitation.expiresAt).toLocaleDateString(),
                                actions: (
                                    <div>
                                        <Button
                                            label="Delete"
                                            variant="ui-control"
                                            icon={MdClose}
                                            disabled={isPendingDeletion(invitation.id)}
                                            onClick={() => setSelectedInvitation(invitation)}
                                        >
                                            <></>
                                        </Button>
                                        <Modal
                                            isOpen={selectedInvitation?.id === invitation.id}
                                            onClose={() => setSelectedInvitation(null)}
                                            header="Delete Invitation"
                                            label="Delete Invitation"
                                            footer={
                                                <>
                                                    <Button
                                                        variant="ui-control"
                                                        onClick={() => setSelectedInvitation(null)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => deleteOrganizationInvitation(invitation)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </>
                                            }
                                        >
                                            Are you sure you want to delete the invitation for <b>{invitation.inviteeEmail}</b>?
                                        </Modal>
                                    </div>
                                ),
                            }))}
                            rowKey={(row) => row.invitationId}
                            disableAction={(row) => isPendingDeletion(row.invitationId)}
                            disableSelect={(row) => isPendingDeletion(row.invitationId)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export function OrganizationInvitationsConnected() {
    const storeProps = useSlice("organizationInvitations");
    return <OrganizationInvitations {...storeProps} />;
}
